import inexpatBackground from '../assets/images/inexpat/cover.jpeg';
import fairpricegroupBackground from '../assets/images/fairpricegroup/cover.jpeg';
import whizcmsBackground from '../assets/images/whizcms/cover.jpeg';
import foodeeBackground from '../assets/images/foodee/cover.jpeg';
import screenoptionalBackground from '../assets/images/screenoptional/cover.jpeg';
import aiwearablesBackground from '../assets/images/aiwearables/cover.jpeg';
import inexpat1 from '../assets/images/inexpat/website-1.jpeg';
import inexpat2 from '../assets/images/inexpat/website-2.jpeg';
import inexpat3 from '../assets/images/inexpat/website-3.jpeg';
import inexpat4 from '../assets/images/inexpat/corporate-profile.jpeg';
import inexpat5 from '../assets/images/inexpat/mobile.jpeg';
import inexpat6 from '../assets/images/inexpat/icons.jpeg';
import whizcms1 from '../assets/images/whizcms/homepage.png';
import whizcms2 from '../assets/images/whizcms/features.png';
import whizcms3 from '../assets/images/whizcms/highlights.png';
import fairpricegroup1 from '../assets/images/fairpricegroup/fairpricegroup-desktop.jpeg';
import fairpricegroup2 from '../assets/images/fairpricegroup/fairpricegroup-mobile.jpeg';
import fairpricegroup3 from '../assets/images/fairpricegroup/fairpricegroup-subpage.jpeg';
import foodee1 from '../assets/images/foodee/food-journey-map.png';
import foodee2 from '../assets/images/foodee/features.png';
import foodee3 from '../assets/images/foodee/user-journey.jpeg';
import foodee4 from '../assets/images/foodee/user-persona.jpeg';
import foodee5 from '../assets/images/foodee/wire-flow.png';
import foodee6 from '../assets/images/foodee/high-fidelity-prototype.jpeg';
import foodee7 from '../assets/images/foodee/style-guide.jpeg';
import screenoptional1 from '../assets/images/screenoptional/feature-aiassistant.mp4';
import screenoptional2 from '../assets/images/screenoptional/ui-desktop.mp4';
import screenoptional3 from '../assets/images/screenoptional/ui-tv.mp4';
import aiwearables1 from '../assets/images/aiwearables/feature-looktoconnect.mp4';
import aiwearables2 from '../assets/images/aiwearables/feature-physicaltodigital.mp4';
import aiwearables3 from '../assets/images/aiwearables/feature-aiassistant.mp4';
import voicefirstBackground from '../assets/images/voicefirst/cover.jpeg';
import voicefirst1 from '../assets/images/voicefirst/feature-closedlid.mp4';
import voicefirst2 from '../assets/images/voicefirst/feature-keywords.mp4';
import voicefirst3 from '../assets/images/voicefirst/feature-presentation.mp4';
import voicefirst4 from '../assets/images/voicefirst/feature-mindmap.mp4';

export const projectData = [
  {
    title: "Voice First",
    category: "Frontend Prototype",
    year: "2024",
    background: voicefirstBackground,
    id: "voicefirst",
    overview: "Voice First allows users to brainstorm with AI through conversation or by capturing handwritten diagrams via a camera feed. At the end, real-time presentation slides and mind maps will be generated based on the summarized discussions, enhancing brainstorming efficiency.",
    sections: [
      { type: "video", src: voicefirst1, alt: "Voice First closed lid feature", id: "voicefirst1" },
      { type: "video", src: voicefirst2, alt: "Voice First keywords feature", id: "voicefirst2" },
      { type: "video", src: voicefirst3, alt: "Voice First presentation feature", id: "voicefirst3" },
      { type: "video", src: voicefirst4, alt: "Voice First mindmap feature", id: "voicefirst4" },
    ]
  },
  {
    title: "AI Wearables",
    category: "Frontend Prototype",
    year: "2024",
    link: "",
    background: aiwearablesBackground,
    id: "aiwearables",
    overview: "The AI Wearables project introduces several advanced features. With Look to Connect, users can instantly connect and screencast simply by looking at a screen, streamlining interactions and enhancing productivity. The Physical to Digital feature allows users to perform hand gestures to capture any real-life content and seamlessly convert it into a digital format, effectively bridging the gap between the physical and digital worlds.",
    sections: [
      { type: "video", src: aiwearables1, alt: "AI Wearables Look to Connect feature", id: "aiwearables1" },
      { type: "video", src: aiwearables2, alt: "AI Wearables Physical to digital feature", id: "aiwearables2" },
      { type: "video", src: aiwearables3, alt: "AI Wearables AI assistant feature", id: "aiwearables3" },
    ]
  },
  {
    title: "Screen Optional",
    category: "Frontend Prototype",
    year: "2023",
    link: "",
    background: screenoptionalBackground,
    id: "screenoptional",
    overview: "This project pioneers a proof of concept in wearable technology, specifically tailored for frontline workers. Central to its design are an integrated camera and speakers that enable users to record real-life scenarios and engage with artificial intelligence seamlessly.",
    sections: [
      { type: "video", src: screenoptional1, alt: "Screen Optional AI assistant feature", id: "screenoptional1" },
      { type: "video", src: screenoptional2, alt: "Screen Optional desktop report UI", id: "screenoptional2" },
      { type: "video", src: screenoptional3, alt: "Screen Optional TV demo UI", id: "screenoptional3" },
    ]
  },
  {
    title: "Foodee",
    category: "UX Design Concept",
    year: "2022",
    link: "",
    background: foodeeBackground,
    id: "foodee",
    overview: "UX design practice exercise highlighting a mobile app design that allows users to easily track their fridge contents and suggests recipes based on available ingredients, promoting efficient use and reducing unnecessary purchases.",
    sections: [
      { type: "image", src: foodee1, alt: "Foodee existing food journey map" },
      { type: "image", src: foodee2, alt: "Foodee revised food journey map" },
      { type: "image", src: foodee3, alt: "Foodee user journey" },
      { type: "image", src: foodee4, alt: "Foodee user persona" },
      { type: "image", src: foodee5, alt: "Foodee wireframe flow" },
      { type: "image", src: foodee6, alt: "Foodee high fidelity prototype" },
      { type: "image", src: foodee7, alt: "Foodee UI style guide" },
    ]
  },
  {
    title: "FairPrice Group",
    category: "Web Development",
    year: "2021",
    link: "https://fairpricegroup.com.sg",
    background: fairpricegroupBackground,
    id: "fairpricegroup",
    overview: "Corporate website developed as a WordPress theme for FairPrice Group. Designed to be user-friendly and accessible, the website is also optimized for mobile devices, ensuring that users can access the site on the go.",
    sections: [
      { type: "image", src: fairpricegroup1, alt: "FairPrice Group desktop website" },
      { type: "image", src: fairpricegroup2, alt: "FairPrice Group mobile website" },
      { type: "image", src: fairpricegroup3, alt: "FairPrice Group desktop website" },
    ]
  },
  {
    title: "WhizCMS",
    category: "Web Design & Development",
    year: "2021",
    link: "https://whizcms.com",
    background: whizcmsBackground,
    id: "whizcms",
    overview: "Responsive static website developed using HTML, CSS, JavaScript and Bootstrap framework to showcase WhizCMS, a content management system designed for small businesses.",
    sections: [
      { type: "image", src: whizcms1, alt: "WhizCMS desktop website" },
      { type: "image", src: whizcms2, alt: "WhizCMS desktop website" },
      { type: "image", src: whizcms3, alt: "WhizCMS desktop website" },
    ]
  },
  {
    title: "IN-EXPAT",
    category: "Web Design & Development",
    year: "2020",
    link: "",
    background: inexpatBackground,
    id: "inexpat",
    overview: "Corporate website designed and developed for an interior design company as a Wordpress theme. Other scope of works also include designing the company's corporate profile and creating custom UI icons for the website.",
    sections: [
      { type: "image", src: inexpat1, alt: "IN-EXPAT desktop website" },
      { type: "image", src: inexpat2, alt: "IN-EXPAT desktop website" },
      { type: "image", src: inexpat3, alt: "IN-EXPAT desktop website" },
      { type: "image", src: inexpat4, alt: "IN-EXPAT corporate profile brochure" },
      { type: "image", src: inexpat5, alt: "IN-EXPAT mobile website" },
      { type: "image", src: inexpat6, alt: "IN-EXPAT icons UI" },
    ]
  }
];
