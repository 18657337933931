import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Header.module.scss';
import gsap from 'gsap';

const Header = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const isActive = location.pathname.includes('/project');
  const currentYear = new Date().getFullYear();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const header = headerRef.current;
    const bottom = bottomRef.current;

    const mediaQuery = window.matchMedia("(min-width: 901px)");

    const animateHeader = () => {
      if (isActive) {
        gsap.to(header, {
          top: 0,
          duration: 1.05,
          ease: 'easeInOut',
        });
        gsap.to(bottom, {
          opacity: 0,
          duration: 0.7,
          ease: 'easeInOut',
        });
      } else {
        gsap.to(header, {
          top: 'auto',
          bottom: 0,
          duration: 1.05,
          ease: 'easeInOut',
        });
        gsap.to(bottom, {
          opacity: 1,
          duration: 0.7,
          ease: 'easeInOut',
        });
      }
    };

    animateHeader();

    const handleResize = () => {
      if (mediaQuery.matches) {
        animateHeader();
        setMenuOpen(false);
      } else {
        gsap.set(header, { clearProps: 'top' });
        gsap.set(bottom, { clearProps: 'opacity' });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isActive]);

  return (
    <div className={`${styles.header} ${menuOpen ? styles.open : ''}`} ref={headerRef}>
      <div className={styles.header__top}>
        <Link to="/" onClick={closeMenu}><h1 className={styles.header__logo}>Shayne Soh</h1></Link>
        <button className={styles.header__hamburger} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </button>
        <div className={styles.header__social}>
          <a href="mailto:shaynesoh@gmail.com">Email&nbsp;&#8599;</a>
          <a href="https://www.linkedin.com/in/shaynesoh">LinkedIn&nbsp;&#8599;</a>
          <a href="https://codepen.io/shaynesoh">CodePen&nbsp;&#8599;</a>
        </div>
      </div>
      <div className={`${styles.header__bottom}`} ref={bottomRef}>
        <div className={styles.header__content}>
          <ul className={styles.header__links}>
            <li><p>Frontend Engineer / UX Designer</p></li>
            <li><p>Currently based in Singapore</p></li>
          </ul>
          <ul className={styles.header__social}>
            <li><a href="mailto:shaynesoh@gmail.com">Email&nbsp;<span>&#8599;</span></a></li>
            <li><a href="https://www.linkedin.com/in/shaynesoh">LinkedIn&nbsp;<span>&#8599;</span></a></li>
            <li><a href="https://codepen.io/shaynesoh">CodePen&nbsp;&#8599;</a></li>
          </ul>
        </div>
        <ul className={styles.header__copyright}>
          <li><p>Copyright © {currentYear}</p></li>
          <li><p>Last Updated / Aug 2024</p></li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
