import React, { useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './ProjectScreen.module.scss';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { projectData } from '../../data/projects';

gsap.registerPlugin(ScrollTrigger);

const ProjectScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sectionsRef = useRef<HTMLDivElement | null>(null);
  const overviewRef = useRef<HTMLDivElement | null>(null);

  const project = location.state?.project;

  const currentIndex = useMemo(() => projectData.findIndex((item) => item.id === project?.id), [project]);
  const nextIndex = useMemo(() => (currentIndex + 1) % projectData.length, [currentIndex]);

  const nextProject = useMemo(() => projectData[nextIndex], [nextIndex]);

  const getRandomColor = useMemo(() => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 50 + Math.random() * 50;
    const lightness = 25 + Math.random() * 25;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }, []);

  const navigateToNextProject = () => {
    window.scrollTo(0, 0);
    navigate(`/project/${nextProject.id}`, { state: { project: nextProject } });
  };

  useEffect(() => {
    if (!project) {
      navigate('/');
      return;
    }

    const createScrollTriggers = () => {
      const sections = gsap.utils.toArray<HTMLElement>(`.${styles['section']}`);
      sections.forEach((section) => {
        const video = section.querySelector('video');
        const trigger = ScrollTrigger.create({
          trigger: section,
          start: 'top center',
          onEnter: () => {
            gsap.to(section, {
              opacity: 1,
              duration: 0.7,
              ease: 'easeInOut',
              onComplete: () => {
                if (video) {
                  video.play()
                }
              },
            });
          },
          onLeave: () => {
            if (video) {
              video.pause();
            }
          },
          onLeaveBack: () => {
            if (video) {
              video.pause();
            }
          },
          onEnterBack: () => {
            gsap.to(section, {
              opacity: 1,
              duration: 0.7,
              ease: 'easeInOut',
              onComplete: () => {
                if (video) {
                  video.play();
                }
              },
            });
          }
        });

        (section as any).scrollTrigger = trigger;
      });
    };

    const createPinningEffects = () => {
      ScrollTrigger.create({
        trigger: sectionsRef.current!,
        start: 'top top',
        end: () => `+=${sectionsRef.current?.scrollHeight}`,
        pin: overviewRef.current!,
        pinSpacing: false,
        onEnterBack: () => {
          gsap.to(overviewRef.current!, {
            position: 'sticky',
            top: 0,
            bottom: 'auto',
            overwrite: 'auto',
          });
        },
        onLeaveBack: () => {
          gsap.to(overviewRef.current!, {
            position: 'relative',
            top: 'auto',
            bottom: 'auto',
            overwrite: 'auto',
          });
        },
      });
    };

    const destroyScrollTriggers = () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };

    const handleResize = () => {
      const mediaQuery = window.matchMedia('(min-width: 993px)');
      if (mediaQuery.matches) {
        createScrollTriggers();
        createPinningEffects();
      } else {
        destroyScrollTriggers();
      }
    };

    const debouncedHandleResize = debounce(handleResize, 100);

    handleResize();

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      destroyScrollTriggers();
    };
  }, [navigate, project]);

  useEffect(() => {
    if (project) {
      gsap.fromTo(
        `.${styles['project__banner']}`,
        {
          height: '0vh',
          bottom: 0,
        },
        {
          delay: 1.05,
          height: '100vh',
          bottom: 'auto',
          duration: 1.05,
          ease: 'power2.out',
        }
      );
    }
  }, [project]);

  return (
    <div className={styles['project']}>
      <div className={styles['project__summary']}>
        <div className={styles['project__banner']}>
          <div
            className={styles['project__overlay']}
            style={{
              backgroundColor: getRandomColor,
            }}
          ></div>
          <div
            className={styles['project__image']}
            style={{
              backgroundImage: `url(${project?.background})`,
            }}
          ></div>
        </div>
        <div className={styles['project__title']}>
          <h1>{project?.title}</h1>
        </div>
      </div>

      <div className={styles['project__content']} ref={sectionsRef}>
        <div className={styles['project__overview']} ref={overviewRef}>
          <div>
            <h2>{project?.title}</h2>
            <h3>{project?.category}</h3>
            <p>{project?.year}</p>
          </div>
          <p>{project?.overview}</p>
          {project?.link && <a href={project?.link}>Product link&nbsp;&#8599;</a>}
        </div>
        <div className={styles['project__details']}>
          {project?.sections.map((section: any) => (
            <section key={section.id} className={styles['section']}>
              {section.type === 'image' && <img src={section.src} alt={section.alt} />}
              {section.type === 'video' && (
                <video
                  id={section.id}
                  loop
                  muted
                  controls
                  src={section.src}
                >
                  <source src={section.src} type="video/mp4" />
                </video>
              )}
            </section>
          ))}
          <section className={styles['project__navigation']}>
            <p>next project</p>
            <button className={styles['button']} onClick={navigateToNextProject}>
              <span className={styles['link']}>{nextProject?.title}&nbsp;</span>
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProjectScreen;

function debounce(func: () => void, wait: number) {
  let timeout: NodeJS.Timeout;
  return function executedFunction() {
    const later = () => {
      clearTimeout(timeout);
      func();
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
