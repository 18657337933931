import './App.css';
import './styles/_global.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import LandingScreen from './screens/LandingScreen/LandingScreen';
import ProjectScreen from './screens/ProjectScreen/ProjectScreen';
import PasswordForm from './components/PasswordForm/PasswordForm';
import { useState } from 'react';

function App() {
  const correctPassword = process.env.REACT_APP_PASSWORD;
  const [authenticated, setAuthenticated] = useState(false);

  const handlePasswordSubmit = (isAuthenticated: boolean) => {
    setAuthenticated(isAuthenticated);
  };

  return (
    
      <div className="App">
        {!authenticated ? (
          <PasswordForm
            correctPassword={correctPassword}
            onPasswordSubmit={handlePasswordSubmit}
          />
        ) : (
          <>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<LandingScreen />} />
              <Route path="/project/:id" element={<ProjectScreen />} />
            </Routes>
          </BrowserRouter>
          </>
        )}
      </div>
 
  );
};

export default App;
