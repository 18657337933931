import React, { useRef, useEffect, useState, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styles from './LandingScreen.module.scss';
import { projectData } from '../../data/projects';
import { useNavigate } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const LandingScreen = () => {
  const navigate = useNavigate();
  const landingRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const numberOfProjects = projectData.length;
  const anglePerItem = 360 / numberOfProjects;

  const calculateProjectWidth = useCallback(() => {
    const baseWidth = 1500;
    const maxProjectWidth = 600;
    const screenWidth = window.innerWidth;
    return Math.min(maxProjectWidth, (screenWidth / baseWidth) * maxProjectWidth);
  }, []);

  const [projectWidth, setProjectWidth] = useState<number>(calculateProjectWidth());
  const [currentProjectIndex, setCurrentProjectIndex] = useState<number>(0);

  const handleResize = useCallback(() => {
    setProjectWidth(calculateProjectWidth());
  }, [calculateProjectWidth]);

  const handleRotate = useCallback((direction: 'next' | 'prev') => {
    setCurrentProjectIndex((prevIndex) => {
      const newIndex = direction === 'next' ? prevIndex + 1 : prevIndex - 1;
      return (newIndex + numberOfProjects) % numberOfProjects;
    });

    gsap.to(carouselRef.current, {
      duration: 0.35,
      rotationY: `+=${direction === 'next' ? -anglePerItem : anglePerItem}`,
    });
  }, [anglePerItem, numberOfProjects]);

  const handleProjectClick = useCallback((item: any) => {
    gsap.to([landingRef.current!], {
      opacity: 0,
      duration: 0.7,
      onComplete: () => {
        navigate(`/project/${item.id}`, { state: { project: item } });
      },
    });
  }, [navigate]);

  useEffect(() => {
    const handleResizeDebounced = debounce(handleResize, 200);

    window.addEventListener('resize', handleResizeDebounced);
    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
    };
  }, [handleResize]);

  useEffect(() => {
    gsap.fromTo(
      carouselRef.current?.children,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.15,
        ease: 'easeInOut',
      }
    );
  }, []);

  const currentProject = projectData[currentProjectIndex];

  return (
    <div className={styles.landing} ref={landingRef}>
      {currentProject && (
        <div className={styles['landing__description']} onClick={() => handleProjectClick(currentProject)}>
          <p>{currentProject.year}</p>
          <h2>{currentProject.title}</h2>
          <h3>{currentProject.category}</h3>
        </div>
      )}
      <div className={styles.landing__container} style={{ width: projectWidth, perspective: projectWidth * 4 }}>
        <div className={styles.landing__carousel} ref={carouselRef}>
          {projectData.map((item, i) => (
            <div
              key={i}
              className={styles.project}
              style={{
                width: `${projectWidth}px`,
                transform: `rotateY(${anglePerItem * i}deg) translateZ(${projectWidth * 1.25}px)`,
                backgroundImage: `url(${item.background})`,
              }}
              onClick={() => handleProjectClick(item)}
            />
          ))}
        </div>
      </div>
      <div className={styles.landing__controls}>
        <div className={styles.prev} onClick={() => handleRotate('prev')}>
          ←
        </div>
        <div className={styles.next} onClick={() => handleRotate('next')}>
          →
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;

function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (this: any) {
    const context = this;
    const args = arguments;
    const later = function () {
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
