import React, { useState } from 'react';
import PasswordFormTypes from './PasswordForm.types';
import styles from './PasswordForm.module.scss'

const PasswordForm = ({ correctPassword, onPasswordSubmit }: PasswordFormTypes) => {
    const [password, setPassword] = useState('');

    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        if (password === correctPassword) {
            onPasswordSubmit(true);
        } else {
            alert('Incorrect password. Please try again.');
        }
    };

    return (
        <div className={styles['password']}>
            <form onSubmit={handleSubmit} className={styles['password__form']}>
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default PasswordForm;
